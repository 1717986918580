import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Col, Label, Row } from 'reactstrap';
import { TextRadioButton, EfcTextRadioButtonInfo } from '@efilecabinet/efc-atlantis-components';
import { DateMDFieldModalTKeys, TranslationFiles, useSafeTranslation } from '../../../../../../../hooks/useSafeTranslation';
import { DateFormatTypeKeyStrings } from '../../../../../MetadataFieldTypes';
import { MDFieldDTO, DateFormatTypes } from '../../../../../../../api/mdFields/mdFieldApiTypes';

export interface DateMDFieldFormatsProps {
    mdField: MDFieldDTO;
    setMDField: Dispatch<SetStateAction<MDFieldDTO>>;
}

export const DateMDFieldFormats = (props: DateMDFieldFormatsProps) => {
    const { mdField, setMDField } = props;

    const { t } = useSafeTranslation(TranslationFiles.DateMDFieldModal);

    const onChecked = (button: EfcTextRadioButtonInfo) => {
        setMDField({ ...mdField, dateProps: { ...mdField.dateProps, dateFormat: DateFormatTypes[button.key as DateFormatTypeKeyStrings] } });
    };

    const getButtonInfos = (): EfcTextRadioButtonInfo[] => {
        return [
            { key: DateFormatTypes[DateFormatTypes.mmddyyyy], labelName: t(DateMDFieldModalTKeys.mmddyyyy), dataId: 'mmddyyyy', checked: true },
            { key: DateFormatTypes[DateFormatTypes.ddmmyyyy], labelName: t(DateMDFieldModalTKeys.ddmmyyyy), dataId: 'ddmmyyyy', },
            { key: DateFormatTypes[DateFormatTypes.yyyymmdd], labelName: t(DateMDFieldModalTKeys.yyyymmdd), dataId: 'yyyymmdd', },
            { key: DateFormatTypes[DateFormatTypes.monthddyyyy], labelName: t(DateMDFieldModalTKeys.monthddyyyy), dataId: 'monthddyy', },
            { key: DateFormatTypes[DateFormatTypes.ddmonthyyyy], labelName: t(DateMDFieldModalTKeys.ddmonthyyyy), dataId: 'ddmonthyyyy' },
        ];
    };

    useEffect(() => {
        setMDField({ ...mdField, dateProps: { ...mdField.dateProps, dateFormat: DateFormatTypes.mmddyyyy } });
    }, []);

    return (
        <Row className='my-5'>
            <Col>
                <Label className='mb-3'>{t(DateMDFieldModalTKeys.SelectFormat)}</Label>
                <TextRadioButton buttonInfos={getButtonInfos()} onChecked={onChecked} />
            </Col>
        </Row>
    );
};