import React, { useEffect, useState } from 'react';
import { PermissionsTKeys, TranslationFiles, useSafeTranslation } from '../../../../hooks/useSafeTranslation';
import { SidesheetPage } from '../../../SideSheet/SidesheetPage';
import { NestedCollapse } from '../../../NestedCollapse/NestedCollapse';
import { Button, Text } from '@efilecabinet/efc-atlantis-components';
import { Row } from 'reactstrap';
import { useLayerContext } from '../../../../app/_context/LayerContext/LayerContext';
import { PermissionDetailsModal } from './DetailsModal/PermissionDetailsModal';
import { ThemeEnum } from '../../../../hooks/useColors';
import { useAuthContext } from '../../../../app/_context/AuthContext';
import { useNodePermissionsUtilities } from '../../../../hooks/nodePermissions/useNodePermissionsUtilities';
import { SidesheetDisplayCard } from '../../../SideSheet/SidesheetDisplayCard';
import { useAccessLinks } from '../../../../hooks/useAccessLinks';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { EditAccessLinkModal } from './EditAccessLinkModal/EditAccessLinkModal';
import { ContextMenuOptions, ContextMenuProps } from '../../../ContextMenu/ContextMenu';
import { useNodeDetailsContext } from '../../_context/NodeDetailsContext/NodeDetailsContext';
import { NodePermissionDTO } from '../../../../api/nodePermissions/nodePermissionApiTypes';
import { AccessLinkDTO } from '../../../../api/accessLinks/accessLinksApiTypes';
import { RoleEnum, UserLicenseEnum } from '../../../../api/roles/roleApiTypes';
import { ExperienceTypeEnum } from '../../../../app/_context/AuthContext/AuthContextTypes';
import { PermissionsSkeletonLoader } from './PermissionsSkeletonLoader';
import { useUtilities } from '../../../../hooks/useUtilities';

interface PermissionsProps {
    onClose?: () => void;
    nodeId?: string;
}

interface PermissionCollection {
    users: NodePermissionDTO[];
    groups: NodePermissionDTO[];
    guests: NodePermissionDTO[];
    accessLinks: NodePermissionDTO[];
}

export const Permissions = ({ onClose, nodeId }: PermissionsProps) => {
    const { t } = useSafeTranslation(TranslationFiles.Permissions);

    const { getNodePermissionsAsync, filterNodePermissionsByRoleType, filterNodePermissionsByCreatedByUserId, filterOtherNodePermissionsByCreatedByUserId } = useNodePermissionsUtilities();
    const { filterNodeAccessLinksByUserId, filterOtherNodeAccessLinksByUserId } = useAccessLinks();
    const { openModal, showToastMessage, closeToastMessage } = useLayerContext();
    const { authUser, hasAuthUser, experienceType } = useAuthContext();
    const { activeNode } = useNodeDetailsContext();
    const { formatDateTime } = useUtilities();

    const [permissions, setPermissions] = useState<NodePermissionDTO[]>([]);
    const [myPermission, setMyPermission] = useState<NodePermissionDTO>();
    const [grantedPermissions, setGrantedPermissions] = useState<PermissionCollection>({ users: [], groups: [], guests: [], accessLinks: [] });
    const [otherPermissions, setOtherPermissions] = useState<PermissionCollection>({ users: [], groups: [], guests: [], accessLinks: [] });
    const [isLoading, setIsLoading] = useState(true);
    const [isAdminOnNode, setIsAdminOnNode] = useState<boolean>(false);
    const [userID, setUserID] = useState<string>('');
    const [userRoleID, setUserRoleID] = useState<string>('');

    const createActions = (item: NodePermissionDTO, isAccessLink = false) => {
        if (!isAdminOnNode) return;

        const contextActions: ContextMenuProps = {
            options: [
                {
                    text: 'Edit',
                    onClick: () => onEditPermissionClick(item, isAccessLink),
                    dataId: `permissions-menu-edit-${item?.id}`,
                },
                {
                    text: 'Remove',
                    onClick: () => onEditPermissionClick(item, isAccessLink),
                    dataId: `permissions-menu-remove-${item?.id}`,
                },
            ] as ContextMenuOptions[],
            dataId: `permissions-menu-${item?.id}`,
        };
        return contextActions;
    };

    const onViewAllClick = () => {
        openModal((closeModal) => <PermissionDetailsModal destroyDetailsModal={closeModal} permissionsState={permissions} editCallback={loadNodePermissions} activeNode={activeNode} />);
    };

    const onEditPermissionClick = (item: NodePermissionDTO | AccessLinkDTO, isAccessLink: boolean) => {
        if (isAccessLink) {
            openModal((closeModal) => <EditAccessLinkModal destroyModal={closeModal} accessLink={item as AccessLinkDTO} />);
        } else {
            console.log('Open Grant Access Modal');
            // openModal((closeModal) => <GrantPermissionsModal destroyModal={closeModal} permission={item as INodePermission} />);
        }
    };

    const fetchNodePermissions = async () => {
        if (!!nodeId) {
            try {
                closeToastMessage('error-loading-permissions-toast');
                const permissionsResult = await getNodePermissionsAsync(nodeId);
                setPermissions(permissionsResult);

                setGrantedPermissions(createCollection(filterNodePermissionsByCreatedByUserId(permissionsResult, userID), filterNodeAccessLinksByUserId(permissionsResult, userID)));

                if (isAdminOnNode) {
                    // Filter out the user's own permission
                    const otherPermissions = filterOtherNodePermissionsByCreatedByUserId(permissionsResult, userID).filter((permission) => permission.roleID?.toString() !== userRoleID);
                    setOtherPermissions(createCollection(otherPermissions, filterOtherNodeAccessLinksByUserId(permissionsResult, userID)));
                }
            } catch (error) {
                console.error('Error fetching Permission data:', error);
                showToastMessage({
                    color: ThemeEnum.Danger,
                    identifier: 'error-loading-permissions-toast',
                    message: t(PermissionsTKeys.ErrorLoadingPermissions),
                });
            }
        }
    };

    const loadNodePermissions = async (showSkeletonLoader = true) => {
        if (showSkeletonLoader) {
            setIsLoading(true);
        }
        if (experienceType === ExperienceTypeEnum.Standard) {
            await fetchNodePermissions();
        }
        setMyPermission(activeNode?.permission);
        setIsLoading(false);
    };

    const createCollection = (permissions: NodePermissionDTO[], accessLinks: NodePermissionDTO[]) => {
        const newCollection: PermissionCollection = {
            users: filterNodePermissionsByRoleType(permissions, RoleEnum.User),
            groups: filterNodePermissionsByRoleType(permissions, RoleEnum.Group),
            guests: filterNodePermissionsByRoleType(permissions, RoleEnum.Guest),
            accessLinks,
        };
        return newCollection;
    };

    const determinePermissionCount = (collection: PermissionCollection) => {
        return collection.users.length + collection.groups.length + collection.guests.length + collection.accessLinks.length;
    };

    const determinePermissionsString = (permission: NodePermissionDTO): string => {
        let permissionString = '';

        if (permission?.admin) {
            permissionString += t(PermissionsTKeys.AdminCheckboxLabel);
            return permissionString;
        }

        if (permission?.view) {
            permissionString += t(PermissionsTKeys.ViewCheckboxLabel);
        }
        if (permission?.download) {
            permissionString += `, ${t(PermissionsTKeys.DownloadCheckboxLabel)}`;
        }
        if (permission?.uploadFiles || permission?.createDirectories) {
            permissionString += `, ${t(PermissionsTKeys.CreateCheckboxLabel)}`;
        }
        if (permission?.write) {
            permissionString += `, ${t(PermissionsTKeys.WriteCheckboxLabel)}`;
        }
        if (permission?.delete) {
            permissionString += `, ${t(PermissionsTKeys.DeleteCheckboxLabel)}`;
        }

        return permissionString;
    };

    const determineIcon = (permission: NodePermissionDTO): IconProp => {
        if (permission?.roleData.roleType == RoleEnum.Group) {
            return 'users';
        }

        switch (permission?.roleData.license) {
            case UserLicenseEnum.Guest:
                return 'user-shield';
            case UserLicenseEnum.Full:
            case UserLicenseEnum.Essentials:
            case UserLicenseEnum.Compliance:
                return 'user-alt';
            default:
                return 'user-alt';
        }
    };

    useEffect(() => {
        if (!!activeNode) {
            setIsAdminOnNode(activeNode.permission.admin);
        }
    }, [activeNode]);

    useEffect(() => {
        if (!!hasAuthUser) {
            const userRoleID = authUser?.userRoles.find((i) => i.accountID == authUser?.accountID && i.roleType != RoleEnum.Group)?.id.toString() as string;
            setUserRoleID(userRoleID);
            const userID = authUser?.userID.toString() as string;
            setUserID(userID);
        }
    }, [hasAuthUser]);

    useEffect(() => {
        if (!!nodeId && !!hasAuthUser && !!userID && !!userRoleID && !!activeNode) {
            loadNodePermissions();
        }
    }, [nodeId, hasAuthUser, experienceType, userID, userRoleID, isAdminOnNode, activeNode]);

    return (
        <>
            <SidesheetPage
                title={t(PermissionsTKeys.Title)}
                onClose={onClose}
                dataId='permissions-sideSheet-pane'
                secondaryButton={
                    <>
                        {isAdminOnNode && (
                            <Button emphasis='low' color='primary' onClick={onViewAllClick}>
                                {t(PermissionsTKeys.ViewAllButton)}
                            </Button>
                        )}
                    </>
                }>
                {!!isLoading ? (
                    <PermissionsSkeletonLoader isAdminOnNode={isAdminOnNode} />
                ) : (
                    <>
                        {isAdminOnNode && (
                            <Button emphasis='med' color='primary' className='my-3 w-50 fs-6 fw-bold'>
                                {t(PermissionsTKeys.GrantAccessButton)}
                            </Button>
                        )}
                        <Text size='lg' className='my-2'>
                            {t(PermissionsTKeys.PermissionsAndSharingHeader)}
                        </Text>
                        <NestedCollapse title={t(PermissionsTKeys.YourPermissionsHeader)} tooltipMessage={t(PermissionsTKeys.GrantedToolTip)} className='my-2' openByDefault={true}>
                            {!!myPermission && <SidesheetDisplayCard icon={{ icon: determineIcon(myPermission) }} title={myPermission.roleData.name ?? myPermission?.roleData.userName} subtitles={[determinePermissionsString(myPermission)]} />}
                        </NestedCollapse>

                        {determinePermissionCount(grantedPermissions as PermissionCollection) > 0 && (
                            <NestedCollapse title={t(PermissionsTKeys.PermissionsYouGrantedHeader)} subtitle={`(${determinePermissionCount(grantedPermissions as PermissionCollection)})`} openByDefault={true}>
                                <>
                                    {grantedPermissions.users.length > 0 && (
                                        <>
                                            <Row>
                                                <Text>{t(PermissionsTKeys.UsersHeader)}</Text>
                                            </Row>
                                            {grantedPermissions?.users.map((permission: NodePermissionDTO) => (
                                                <SidesheetDisplayCard key={permission.id} icon={{ icon: determineIcon(permission) }} title={permission?.roleData.name ?? permission?.roleData.userName} subtitles={[determinePermissionsString(permission)]} actions={createActions(permission)} />
                                            ))}
                                        </>
                                    )}

                                    {grantedPermissions.groups.length > 0 && (
                                        <>
                                            <Row>
                                                <Text>{t(PermissionsTKeys.GroupsHeader)}</Text>
                                            </Row>
                                            {grantedPermissions?.groups.map((permission: NodePermissionDTO) => (
                                                <SidesheetDisplayCard key={permission.id} icon={{ icon: determineIcon(permission) }} title={permission?.roleData.name ?? permission?.roleData.userName} subtitles={[determinePermissionsString(permission)]} actions={createActions(permission)} />
                                            ))}
                                        </>
                                    )}

                                    {grantedPermissions.guests.length > 0 && (
                                        <>
                                            <Row>
                                                <Text>{t(PermissionsTKeys.GuestUsersHeader)}</Text>
                                            </Row>
                                            {grantedPermissions?.guests.map((permission: NodePermissionDTO) => (
                                                <SidesheetDisplayCard key={permission.id} icon={{ icon: determineIcon(permission) }} title={permission?.roleData.name ?? permission?.roleData.userName} subtitles={[determinePermissionsString(permission)]} actions={createActions(permission)} />
                                            ))}
                                        </>
                                    )}

                                    {grantedPermissions.accessLinks.length > 0 && (
                                        <>
                                            <Row>
                                                <Text>{t(PermissionsTKeys.AccessLinksHeader)}</Text>
                                            </Row>
                                            {grantedPermissions?.accessLinks.map((accessLink: NodePermissionDTO) => (
                                                <SidesheetDisplayCard key={accessLink.id} icon={{ icon: 'link' }} title={t(PermissionsTKeys.AccessLinkLabel, { date: formatDateTime(accessLink.createdOn) })} subtitles={[determinePermissionsString(accessLink)]} actions={createActions(accessLink, true)} />
                                            ))}
                                        </>
                                    )}
                                </>
                            </NestedCollapse>
                        )}

                        {isAdminOnNode && determinePermissionCount(otherPermissions as PermissionCollection) > 0 && (
                            <>
                                <Row className='my-3 ms-1 opacity-25 square border border-secondary' />

                                <Text size='lg' className='my-2'>
                                    {t(PermissionsTKeys.AllOtherAccessHeader)}
                                </Text>
                                {otherPermissions.users.length > 0 && (
                                    <NestedCollapse title={t(PermissionsTKeys.UsersHeader)} subtitle={`(${otherPermissions.users.length})`} className='my-2' openByDefault={true}>
                                        {otherPermissions.users.map((permission: NodePermissionDTO) => (
                                            <SidesheetDisplayCard key={permission.id} icon={{ icon: determineIcon(permission) }} title={permission?.roleData.name ?? permission?.roleData.userName} subtitles={[determinePermissionsString(permission)]} actions={createActions(permission)} />
                                        ))}
                                    </NestedCollapse>
                                )}

                                {otherPermissions.groups.length > 0 && (
                                    <NestedCollapse title={t(PermissionsTKeys.GroupsHeader)} subtitle={`(${otherPermissions.groups.length})`} className='my-2' openByDefault={true}>
                                        {otherPermissions.groups.map((permission: NodePermissionDTO) => (
                                            <SidesheetDisplayCard key={permission.id} icon={{ icon: determineIcon(permission) }} title={permission?.roleData.name ?? permission?.roleData.userName} subtitles={[determinePermissionsString(permission)]} actions={createActions(permission)} />
                                        ))}
                                    </NestedCollapse>
                                )}

                                {otherPermissions.guests.length > 0 && (
                                    <NestedCollapse title={t(PermissionsTKeys.GuestUsersHeader)} subtitle={`(${otherPermissions?.guests.length})`} className='my-2' openByDefault={true}>
                                        {otherPermissions.guests.map((permission: NodePermissionDTO) => (
                                            <SidesheetDisplayCard key={permission.id} icon={{ icon: determineIcon(permission) }} title={permission?.roleData.name ?? permission?.roleData.userName} subtitles={[determinePermissionsString(permission)]} actions={createActions(permission)} />
                                        ))}
                                    </NestedCollapse>
                                )}

                                {otherPermissions.accessLinks.length > 0 && (
                                    <NestedCollapse title={t(PermissionsTKeys.AccessLinksHeader)} subtitle={`(${otherPermissions.accessLinks.length})`} className='my-2' openByDefault={true}>
                                        {otherPermissions.accessLinks.map((accessLink: NodePermissionDTO) => (
                                            <SidesheetDisplayCard key={accessLink.id} icon={{ icon: 'link' }} title={t(PermissionsTKeys.AccessLinkLabel, { date: formatDateTime(accessLink.createdOn) })} subtitles={[determinePermissionsString(accessLink)]} actions={createActions(accessLink, true)} />
                                        ))}
                                    </NestedCollapse>
                                )}
                            </>
                        )}
                    </>
                )}
            </SidesheetPage>
        </>
    );
};
