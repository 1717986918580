import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Col, Form, Label, Row } from 'reactstrap';
import { Input, FormGroup, Text } from '@efilecabinet/efc-atlantis-components';
import { AddressMDFieldModalTKeys, TranslationFiles, useSafeTranslation } from '../../../../../../../hooks/useSafeTranslation';
import { AddressMDFieldDTO, MDFieldDTO } from '../../../../../../../api/mdFields/mdFieldApiTypes';
import './AddressMDFieldFormats.css';

export interface AddressMDFieldFormatsProps {
    mdField: MDFieldDTO;
    setMDField: Dispatch<SetStateAction<MDFieldDTO>>;
}

export const AddressMDFieldFormats = (props: AddressMDFieldFormatsProps) => {
    const { mdField, setMDField } = props;

    const { t } = useSafeTranslation(TranslationFiles.AddressMDFieldModal);

    useEffect(() => {
        setMDField({ ...mdField, addressProps: { ...mdField.addressProps as AddressMDFieldDTO, includeAddressOne: true, includeAddressTwo: false, includeCity: true, includeState: true, includePostal: true, includeCountry: true } });
    }, []);

    return (
        <Row className='mt-2 md-field-modal-row-spacing'>
            <Col xs='auto' className='position-relative'>
                <Label for='selectFormat'>{t(AddressMDFieldModalTKeys.SelectFormat)}</Label>
                <Form>
                    <FormGroup>
                        <Row className='mb-2 ms-1 d-flex align-items-center'>
                            <Input
                                id='show-include-address-one'
                                dataId='cbIncludeAddressOne'
                                className='mb-1 pe-0 pointer md-field-modal-checkbox'
                                type='checkbox'
                                defaultChecked={mdField.addressProps?.includeAddressOne} onClick={() => setMDField({ ...mdField, addressProps: { ...mdField.addressProps as AddressMDFieldDTO, includeAddressOne: !mdField.addressProps?.includeAddressOne } })}
                            />
                            <div className={`ms-2 rounded border d-flex align-items-center md-field-modal-address-format-large ${!mdField.addressProps?.includeAddressOne ? 'md-field-modal-address-format-disabled' : ''}`}>
                                <Text className='md-field-modal-address-format-large-text'>{t(AddressMDFieldModalTKeys.AddressLineOne)}</Text>
                            </div>
                        </Row>

                        <Row className='mb-2 ms-1 d-flex align-items-center'>
                            <Input
                                id='show-include-address-two'
                                dataId='cbIncludeAddressTwo'
                                className='mb-1 pe-0 pointer md-field-modal-checkbox'
                                type='checkbox'
                                defaultChecked={mdField.addressProps?.includeAddressTwo} onClick={() => setMDField({ ...mdField, addressProps: { ...mdField.addressProps as AddressMDFieldDTO, includeAddressTwo: !mdField.addressProps?.includeAddressTwo } })}
                            />
                            <div className={`ms-2 rounded border d-flex align-items-center md-field-modal-address-format-large ${!mdField.addressProps?.includeAddressTwo ? 'md-field-modal-address-format-disabled' : ''}`}>
                                <Text className='md-field-modal-address-format-large-text'>{t(AddressMDFieldModalTKeys.AddressLineTwo)}</Text>
                            </div>
                        </Row>

                        <Row className='mb-2 ms-1 d-flex align-items-center'>
                            <Input
                                id='show-include-city'
                                dataId='cbIncludeCity'
                                className='mb-1 pe-0 pointer md-field-modal-checkbox'
                                type='checkbox' defaultChecked={mdField.addressProps?.includeCity} onClick={() => setMDField({ ...mdField, addressProps: { ...mdField.addressProps as AddressMDFieldDTO, includeCity: !mdField.addressProps?.includeCity } })}
                            />
                            <div className={`ms-2 rounded border d-flex align-items-center md-field-modal-address-format-large ${!mdField.addressProps?.includeCity ? 'md-field-modal-address-format-disabled' : ''}`}>
                                <Text className='md-field-modal-address-format-large-text'>{t(AddressMDFieldModalTKeys.City)}</Text>
                            </div>
                        </Row>

                        <Row>
                            <Col>
                                <Row className='mb-2 ms-1 d-flex align-items-center'>
                                    <Input
                                        id='show-include-state'
                                        dataId='cbIncludeState'
                                        className='mb-1 pe-0 pointer md-field-modal-checkbox'
                                        type='checkbox'
                                        defaultChecked={mdField.addressProps?.includeState} onClick={() => setMDField({ ...mdField, addressProps: { ...mdField.addressProps as AddressMDFieldDTO, includeState: !mdField.addressProps?.includeState } })}
                                    />
                                    <div className={`ms-2 rounded border d-flex align-items-center md-field-modal-address-format-small ${!mdField.addressProps?.includeState ? 'md-field-modal-address-format-disabled' : ''}`}>
                                        <Text className='md-field-modal-address-format-small-text'>{t(AddressMDFieldModalTKeys.State)}</Text>
                                    </div>
                                </Row>

                                <Row className='mb-2 ms-1 d-flex align-items-center'>
                                    <Input
                                        id='show-include-country'
                                        dataId='cbIncludeCountry'
                                        className='mb-1 pe-0 pointer md-field-modal-checkbox'
                                        type='checkbox'
                                        defaultChecked={mdField.addressProps?.includeCountry} onClick={() => setMDField({ ...mdField, addressProps: { ...mdField.addressProps as AddressMDFieldDTO, includeCountry: !mdField.addressProps?.includeCountry } })}
                                    />
                                    <div className={`ms-2 rounded border d-flex align-items-center md-field-modal-address-format-small ${!mdField.addressProps?.includeCountry ? 'md-field-modal-address-format-disabled' : ''}`}>
                                        <Text className='md-field-modal-address-format-small-text'>{t(AddressMDFieldModalTKeys.Country)}</Text>
                                    </div>
                                </Row>
                            </Col>
                            <Col>
                                <Row className='d-flex justify-content-end align-items-center'>
                                    <Input id='show-include-postal'
                                        dataId='cbIncludePostal'
                                        className='mb-1 pe-0 pointer md-field-modal-checkbox'
                                        type='checkbox'
                                        defaultChecked={mdField.addressProps?.includePostal}
                                        onClick={() => setMDField({ ...mdField, addressProps: { ...mdField.addressProps as AddressMDFieldDTO, includePostal: !mdField.addressProps?.includePostal } })}
                                    />
                                    <div className={`ms-2 rounded border d-flex align-items-center md-field-modal-address-format-small ${!mdField.addressProps?.includePostal ? 'md-field-modal-address-format-disabled' : ''}`}>
                                        <Text className='md-field-modal-address-format-small-text'>{t(AddressMDFieldModalTKeys.Postal)}</Text>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                    </FormGroup>
                </Form>
            </Col>
        </Row>
    );
};