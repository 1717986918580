import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormGroup, Input, Text, Tooltip } from '@efilecabinet/efc-atlantis-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNodePermissionsUtilities } from '../../../../../hooks/nodePermissions/useNodePermissionsUtilities';
import { useColors } from '../../../../../hooks/useColors';
import { PermissionsTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { useAuthContext } from '../../../../../app/_context/AuthContext';
import { NodePermissionDTO } from '../../../../../api/nodePermissions/nodePermissionApiTypes';
import { NodePermissionLevelEnum } from '../../../../../hooks/nodePermissions/nodePermissionTypes';
import { RoleEnum } from '../../../../../api/roles/roleApiTypes';
import { useUtilities } from '../../../../../hooks/useUtilities';
import './PermissionDetailsTableRow.css';

interface PermissionDetailsTableRowProps {
    permission: NodePermissionDTO;
    isEditMode: boolean;
    onCheckboxClick: (e: ChangeEvent<HTMLInputElement>, permissionId: number, permissionLevel: NodePermissionLevelEnum, prevPermissionLvl?: NodePermissionLevelEnum) => void;
    onDeleteClick: (permissionId: number) => void;
    activeNodeId?: string;
}

export const PermissionDetailsTableRow = ({ permission, isEditMode, onCheckboxClick, onDeleteClick, activeNodeId }: PermissionDetailsTableRowProps) => {
    const { t } = useSafeTranslation(TranslationFiles.Permissions);
    const { atlantisPrimary } = useColors();
    const { checkCreateBox, getRoleIcon } = useNodePermissionsUtilities();
    const { hasAuthUser, authUser } = useAuthContext();
    const { formatDateTime } = useUtilities();

    const [isCreateChecked, setIsCreateChecked] = useState<boolean>(false);
    const [isOwnPermission, setIsOwnPermission] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [isInherited, setIsInherited] = useState<boolean>(false);
    const [isToolTipOpen, setIsToolTipOpen] = useState<boolean>(false);

    const lowerPermissionCheckboxClass = 'lower-permission-checkbox';

    const toggleTooltip = () => setIsToolTipOpen((prevState) => !prevState);

    useEffect(() => {
        setIsInherited(permission.nodeID != activeNodeId);
        setIsCreateChecked(checkCreateBox(permission));
    }, [permission]);

    useEffect(() => {
        if (!!hasAuthUser && !!authUser) {
            setIsOwnPermission(permission.roleData.userID == authUser.userID);
        }
    }, [hasAuthUser]);

    useEffect(() => {
        if (!!isEditMode && !!isOwnPermission) {
            setDisabled(true);
        } else if (!!isEditMode && !isOwnPermission) {
            setDisabled(false);
        } else if (!isEditMode) {
            setDisabled(true);
        }
    }, [isOwnPermission, isEditMode]);

    return (
        <tr className='permission-details-table-row'>
            <td>
                <div className='d-flex align-items-center'>
                    {getRoleIcon(permission.roleData)}
                    {permission.roleData.anonymous ? <Text className='ms-1'>{t(PermissionsTKeys.AccessLinkLabel, { date: formatDateTime(permission.createdOn) })}</Text> : <Text className='ms-1'>{`${permission.roleData.name}`}</Text>}
                </div>
            </td>
            <td>
                {!!isInherited && (
                    <>
                        <FontAwesomeIcon id={`permission-details-inheritance-icon-${permission.id}`} data-id={`inheritance-icon-${permission.id}`} icon='sitemap' color={atlantisPrimary} />
                        <Tooltip target={`permission-details-inheritance-icon-${permission.id}`} isOpen={isToolTipOpen} toggle={toggleTooltip}>
                            {`${t(PermissionsTKeys.InheritedFromTooltip)} ${permission.name}`}
                        </Tooltip>
                    </>
                )}
            </td>
            <td>
                <FormGroup check>
                    <Input dataId={`view-permission-check-${permission.id}`} type='checkbox' checked={permission.view} disabled={permission.view} className={`pointer ${permission.download ? lowerPermissionCheckboxClass : ''}`} onChange={(e) => onCheckboxClick(e, permission.id, NodePermissionLevelEnum.View)} />
                </FormGroup>
            </td>
            <td>
                <FormGroup check>
                    <Input dataId={`download-permission-check-${permission.id}`} type='checkbox' checked={permission.download} disabled={disabled} className={`pointer ${permission.uploadFiles || permission.createDirectories ? lowerPermissionCheckboxClass : ''}`} onChange={(e) => onCheckboxClick(e, permission.id, NodePermissionLevelEnum.Download, NodePermissionLevelEnum.View)} />
                </FormGroup>
            </td>
            <td>
                <FormGroup check>
                    <Input dataId={`create-permission-check-${permission.id}`} type='checkbox' checked={isCreateChecked} disabled={permission.roleData.anonymous || disabled} className={`pointer ${permission.write ? lowerPermissionCheckboxClass : ''}`} onChange={(e) => onCheckboxClick(e, permission.id, NodePermissionLevelEnum.Create, NodePermissionLevelEnum.Download)} />
                </FormGroup>
            </td>
            <td>
                <FormGroup check>
                    <Input dataId={`write-permission-check-${permission.id}`} type='checkbox' checked={permission.write} disabled={permission.roleData.anonymous || disabled} className={`pointer ${permission.delete ? lowerPermissionCheckboxClass : ''}`} onChange={(e) => onCheckboxClick(e, permission.id, NodePermissionLevelEnum.Write, NodePermissionLevelEnum.Create)} />
                </FormGroup>
            </td>
            <td>
                <FormGroup check>
                    <Input dataId={`delete-permission-check-${permission.id}`} type='checkbox' checked={permission.delete} disabled={permission.roleData.anonymous || disabled} className={`pointer ${permission.admin ? lowerPermissionCheckboxClass : ''}`} onChange={(e) => onCheckboxClick(e, permission.id, NodePermissionLevelEnum.Delete, NodePermissionLevelEnum.Write)} />
                </FormGroup>
            </td>
            <td>
                <FormGroup check>
                    <Input dataId={`admin-permission-check-${permission.id}`} type='checkbox' checked={permission.admin} className='pointer' disabled={permission.roleData.anonymous || disabled || permission.roleData.roleType == RoleEnum.Guest /* TODO: Check system permission if admin permission can be given */} onChange={(e) => onCheckboxClick(e, permission.id, NodePermissionLevelEnum.Admin, NodePermissionLevelEnum.Delete)} />
                </FormGroup>
            </td>
            <td>
                <FormGroup check>
                    <Input dataId={`override-permission-check-${permission.id}`} type='checkbox' checked={permission.enforce} className='pointer' disabled={permission.roleData.anonymous || disabled || permission.roleData.roleType == RoleEnum.Group} onChange={(e) => onCheckboxClick(e, permission.id, NodePermissionLevelEnum.Override)} />
                </FormGroup>
            </td>
            <td>
                <FormGroup check>
                    <Input dataId={`restrict-permission-check-${permission.id}`} type='checkbox' checked={permission.restrict} className='pointer' disabled={permission.roleData.anonymous || disabled} onChange={(e) => onCheckboxClick(e, permission.id, NodePermissionLevelEnum.RestrictAll)} />
                </FormGroup>
            </td>
            {!isInherited ? (
                <td className='pointer'>
                    <FontAwesomeIcon icon='trash-alt' className='delete-permission-icon' data-id={`delete-permission-icon=${permission.id}`} style={{ visibility: isEditMode ? undefined : 'hidden' }} onClick={() => onDeleteClick(permission.id)} />
                </td>
            ) : (
                <td></td>
            )}
        </tr>
    );
};
