import { NodeDTO, NodeType } from "../api/node/nodeApiTypes";

export const useFileUtilities = () => {

    const getFileExtension = (filename: string) => {
        return '.' + filename?.split('.').pop()?.toLowerCase();
    };

    const convertFileVersionDateTime = (input: string) => {
        const date = new Date(input);
        return date.toLocaleDateString() + ' | ' + date.toLocaleTimeString([], { timeStyle: 'short' });
    };

    const nodeTypeIsNotFile = (node: NodeDTO | undefined) => {
        // prettier-ignore
        return (
            node?.systemType === NodeType.Account ||
            node?.systemType === NodeType.Cabinet ||
            node?.systemType === NodeType.Drawer ||
            node?.systemType === NodeType.Folder ||
            node?.systemType === NodeType.TemplateFolder ||
            node?.systemType === NodeType.Template
        );
    };

    return {
        getFileExtension,
        convertFileVersionDateTime,
        nodeTypeIsNotFile,
    };
};