import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'reactstrap';
import { Text } from '@efilecabinet/efc-atlantis-components';
import { useColors } from '../../../../../hooks/useColors';
import { PermissionsTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { useIcons } from '../../../../../hooks/useIcons';
import { NodeType } from '../../../../../api/node/nodeApiTypes';

interface PermissionDetailsModalHeaderProps {
    isEditMode: boolean;
    nodeName: string;
    nodeType: NodeType;
}

export const PermissionDetailsModalHeader = ({ isEditMode, nodeName, nodeType }: PermissionDetailsModalHeaderProps) => {

    const { t } = useSafeTranslation(TranslationFiles.Permissions);
    const { atlantisPrimary } = useColors();
    const { getNodeIconProps } = useIcons();

    function getHeaderText() {
        return isEditMode ? t(PermissionsTKeys.EditDetailsModalHeader) : t(PermissionsTKeys.ViewDetailsModalHeader);
    }

    function getSelectedNodeIconProps() {
        return getNodeIconProps(nodeType, nodeName.slice(nodeName.lastIndexOf('.'))).icon;
    }

    return (
        <>
            <Row>
                <Col className='d-flex align-items-center'>
                    {isEditMode &&
                        <FontAwesomeIcon icon={['far', 'edit']} color={atlantisPrimary} className='me-2' />
                    }
                    <Text semibold>{getHeaderText()}</Text>
                    <FontAwesomeIcon icon={getSelectedNodeIconProps()} className='mx-2' />
                    <Text semibold>{nodeName}</Text>
                </Col>
            </Row>
        </>
    );
};