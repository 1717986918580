import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Collapse, FormGroup, Label, Input } from '@efilecabinet/efc-atlantis-components';
import { CheckboxMDFieldModalTKeys, TranslationFiles, useSafeTranslation } from '../../../../../../../hooks/useSafeTranslation';
import { MDFieldAdvancedFormatsCollapse } from '../../__shared/MdFieldAdvancedFormatsCollapse/MdFieldAdvancedFormatsCollapse';
import { CheckboxMDFieldDTO, MDFieldDTO } from '../../../../../../../api/mdFields/mdFieldApiTypes';

export interface CheckboxMDFieldAdvancedFormatsProps {
    mdField: MDFieldDTO;
    setMDField: (mdField: MDFieldDTO) => void;
}

export const CheckboxMDFieldAdvancedFormats = (props: CheckboxMDFieldAdvancedFormatsProps) => {
    const { mdField, setMDField } = props;

    const { t } = useSafeTranslation(TranslationFiles.CheckboxMDFieldModal);

    const [isCollapseOpen, setIsCollapseOpen] = useState(false);
    const [checkboxCheckedOption, setCheckboxCheckedOption] = useState('optionUnchecked');

    const toggleCollapse = () => {
        setIsCollapseOpen(!isCollapseOpen);
    };

    const handleOptionChange = (changeEvent: any) => {
        setCheckboxCheckedOption(changeEvent.target.value);
        setMDField({ ...mdField, checkboxProps: { ...mdField.checkboxProps as CheckboxMDFieldDTO, defaultValue: changeEvent.target.value === 'optionChecked' } });
    };

    useEffect(() => {
        setCheckboxCheckedOption('optionUnchecked');
        setMDField({ ...mdField, checkboxProps: { ...mdField.checkboxProps as CheckboxMDFieldDTO, defaultValue: checkboxCheckedOption === 'optionChecked' } });
    }, []);

    return (
        <>
            <MDFieldAdvancedFormatsCollapse className='mt-3' isOpen={isCollapseOpen} toggleCollapse={toggleCollapse} />

            <Row className='mt-3'>
                <Col>
                    <Collapse isOpen={isCollapseOpen}>

                        <Label for='unchecked'>{t(CheckboxMDFieldModalTKeys.CheckedTitle)}</Label>
                        <FormGroup check>
                            <Input name='unchecked'
                                type='radio'
                                defaultChecked={true}
                                value='optionUnchecked'
                                checked={checkboxCheckedOption === 'optionUnchecked'}
                                onChange={handleOptionChange} />
                            <Label check className='ms-2'>
                                {t(CheckboxMDFieldModalTKeys.UncheckedLabel)}
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                            <Input name='checked'
                                type='radio'
                                value='optionChecked'
                                checked={checkboxCheckedOption === 'optionChecked'}
                                onChange={handleOptionChange} />
                            <Label check className='ms-2'>
                                {t(CheckboxMDFieldModalTKeys.CheckedLabel)}
                            </Label>
                        </FormGroup>

                    </Collapse>
                </Col>
            </Row>
        </>
    );
};