import React, { useState } from 'react';
import { Modal } from 'reactstrap';
import { PermissionDetailsTable } from './PermissionDetailsTable';
import { PermissionDetailsModalHeader } from './PermissionDetailsModalHeader';
import { Button } from '@efilecabinet/efc-atlantis-components';
import { PermissionsTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { useNodePermissionsUtilities } from '../../../../../hooks/nodePermissions/useNodePermissionsUtilities';
import { useLayerContext } from '../../../../../app/_context/LayerContext/LayerContext';
import { DeletePermissionsConfirmModal } from './DeletePermissionsConfirmModal';
import { DockedBar } from '../../../../DockedBar';
import { ThemeEnum } from '../../../../../hooks/useColors';
import { ToastMessages } from '../../../../Nav/NavMenu/ToastMessages';
import { useIcons } from '../../../../../hooks/useIcons';
import { FullScreenOverlay } from '../../../../FullScreenOverlay/FullScreenOverlay';
import { NodePermissionDTO } from '../../../../../api/nodePermissions/nodePermissionApiTypes';
import { NodeDTO, NodeType } from '../../../../../api/node/nodeApiTypes';

interface PermissionDetailsModalProps {
    destroyDetailsModal: () => void;
    permissionsState: NodePermissionDTO[];
    editCallback?: (showSkeletonLoader: boolean) => void;
    activeNode: NodeDTO | undefined;
}

export const PermissionDetailsModal = ({ destroyDetailsModal, permissionsState, editCallback, activeNode }: PermissionDetailsModalProps) => {
    const { t } = useSafeTranslation(TranslationFiles.Permissions);
    const { showToastMessage } = useLayerContext();
    const { spinnerIconProps } = useIcons();
    const { openModal } = useLayerContext();
    const { updateAndCreatePermissions, categorizePermissionChanges } = useNodePermissionsUtilities();

    const [permissionsView, setPermissionsView] = useState<NodePermissionDTO[]>(permissionsState);
    const [permissionsEdit, setPermissionsEdit] = useState<NodePermissionDTO[]>(permissionsState);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [isGrantAccessMode, setIsGrantAccessMode] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    function onEdit() {
        setIsEditMode(true);
    }

    function onGrantAccess() {
        setIsGrantAccessMode(true);
        // TODO: Open Grant Access Modal
    }

    function onCancel() {
        setPermissionsEdit(permissionsView);
        setIsEditMode(false);
    }

    const onSuccessfulEdit = () => {
        showToastMessage({
            color: ThemeEnum.Success,
            identifier: 'edit-permissions-success-toast',
            message: t(PermissionsTKeys.EditSuccess),
            timeout: 2500,
        });

        const { permissionsToCreate } = categorizePermissionChanges(permissionsView, permissionsEdit, activeNode?.id);

        // Replace any newly created permissions so the inheritance icon shows correctly in the table.
        const updatedPermissions = permissionsEdit.map((permissionEdit) => {
            const matchingPermission = permissionsToCreate.find((newPermission) => newPermission.id === permissionEdit.id);
            return matchingPermission ? matchingPermission : permissionEdit;
        });

        setPermissionsView(updatedPermissions);

        setIsEditMode(false);
        editCallback && editCallback(false);
    };

    const handleClickSave = async () => {
        const { permissionsToRemove, permissionsToCreate, permissionsToUpdate } = categorizePermissionChanges(permissionsView, permissionsEdit, activeNode?.id);

        if (permissionsToRemove.length) {
            openModal((closeModal) => <DeletePermissionsConfirmModal closeModal={closeModal} onCancel={onCancel} successCallback={onSuccessfulEdit} permissionsToRemove={permissionsToRemove} permissionsToCreate={permissionsToCreate} permissionsToUpdate={permissionsToUpdate} />);
        } else {
            updateNodePermissions(permissionsToUpdate, permissionsToCreate);
        }
    };

    const updateNodePermissions = async (permissionsToUpdate: NodePermissionDTO[], permissionsToCreate: NodePermissionDTO[]) => {
        setIsLoading(true);
        try {
            await updateAndCreatePermissions(permissionsToUpdate, permissionsToCreate);
            onSuccessfulEdit();
        } catch (error: any) {
            console.log('Error updating permissions', error);
            showToastMessage({
                color: ThemeEnum.Danger,
                identifier: 'edit-permissions-error-toast',
                message: t(PermissionsTKeys.EditError, { error: error?.message }),
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Modal fullscreen isOpen={!isGrantAccessMode}>
                <div className='modal-header'>
                    {!!permissionsView && <PermissionDetailsModalHeader isEditMode={isEditMode} nodeName={activeNode?.name ?? 'Default'} nodeType={activeNode?.systemType as NodeType} />}
                    <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close' onClick={destroyDetailsModal} data-id='close-permissions-details'></button>
                </div>

                <div className='modal-body m-3'>{isEditMode ? <PermissionDetailsTable
                        permissionsState={[permissionsEdit, setPermissionsEdit]}
                        isEditMode={isEditMode}
                        activeNode={activeNode} /> :
                    <PermissionDetailsTable
                        permissionsState={[permissionsView, setPermissionsView]}
                        isEditMode={isEditMode}
                        editCallback={onEdit}
                        grantAccessCallback={onGrantAccess}
                        activeNode={activeNode} />}
                </div>

                {isEditMode && (
                    <DockedBar isDocked={true}>
                        <Button emphasis='med' onClick={onCancel} dataId='permissions-details-cancel' disabled={isLoading}>
                            {t(PermissionsTKeys.CancelButton)}
                        </Button>
                        <Button emphasis='high' color={ThemeEnum.Primary} onClick={handleClickSave} className='ms-2' dataId='permissions-details-save' icon={isLoading ? spinnerIconProps : undefined} disabled={isLoading}>
                            {t(PermissionsTKeys.SaveEditsButton)}
                        </Button>
                    </DockedBar>
                )}

                <FullScreenOverlay isVisible={isLoading} />
                <ToastMessages />
            </Modal>
        </>
    );
};
