import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { MDGroupDTO, QueryMDGroupsDTO } from './mdGroupsApiTypes';

export const useMDGroupsApi = () => {

    const getMDGroup = async (accountId: number, mdGroupId: string): Promise<MDGroupDTO> => {
        const resp: AxiosResponse = await Api.get(`api/metadataGroup/${accountId}/${mdGroupId}`);
        return resp.data as MDGroupDTO;
    };

    const getAccountMDGroups = async (accountId: number, start: number, count: number): Promise<MDGroupDTO[]> => {
        const resp: AxiosResponse = await Api.get(`api/metadataGroup/account/${accountId}/${start}/${count}`);
        return resp.data as MDGroupDTO[];
    };

    const getMDGroups = async (mdGroupRequest: QueryMDGroupsDTO): Promise<MDGroupDTO[]> => {
        const resp: AxiosResponse = await Api.post('api/metadataGroup/query', mdGroupRequest);
        return resp.data as MDGroupDTO[];
    };

    const createMDGroup = async (mdGroup: MDGroupDTO): Promise<MDGroupDTO> => {
        const resp: AxiosResponse = await Api.post('api/metadataGroup', mdGroup);
        return resp.data as MDGroupDTO;
    };

    const updateMDGroup = async (mdGroup: MDGroupDTO): Promise<MDGroupDTO> => {
        const resp: AxiosResponse = await Api.put('api/metadataGroup', mdGroup);
        return resp.data as MDGroupDTO;
    };

    const deleteMDGroup = async (accountId: number, mdGroupId: string): Promise<MDGroupDTO> => {
        const resp: AxiosResponse = await Api.delete(`api/metadataGroup/${accountId}/${mdGroupId}`);
        return resp.data as MDGroupDTO;
    };

    return {
        getMDGroup,
        getAccountMDGroups,
        getMDGroups,
        createMDGroup,
        updateMDGroup,
        deleteMDGroup
    };
};