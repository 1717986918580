import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Collapse, FormGroup, Input, Label } from '@efilecabinet/efc-atlantis-components';
import { useForm } from '../../../../../../../hooks/useForm';
import { MDFieldDropdownListModalTKeys, TranslationFiles, useSafeTranslation } from '../../../../../../../hooks/useSafeTranslation';
import { MDFieldAdvancedFormatsCollapse } from '../../__shared/MdFieldAdvancedFormatsCollapse/MdFieldAdvancedFormatsCollapse';
import { DropdownListMDFieldDTO, MDFieldDTO } from '../../../../../../../api/mdFields/mdFieldApiTypes';

export interface DropdownListMDFieldAdvancedFormatsProps {
    mdField: MDFieldDTO;
    setMDField: (mdField: MDFieldDTO) => void;
}

enum DropdownListFormInputIds {
    AllowNewOptions = 'allowNewOptions',
}

export const DropdownListMDFieldAdvancedFormats = (props: DropdownListMDFieldAdvancedFormatsProps) => {
    const { mdField, setMDField } = props;

    const { t } = useSafeTranslation(TranslationFiles.MDFieldDropdownListModal);

    const [isCollapseOpen, setIsCollapseOpen] = useState(false);

    const initModel: { allowNewOptions: boolean; } = {
        allowNewOptions: mdField.dropdownListProps?.allowNewOptions ?? false,
    };

    const form = useForm(initModel);

    const toggleCollapse = () => {
        setIsCollapseOpen(!isCollapseOpen);
    };

    useEffect(() => {
        setMDField({ ...mdField, dropdownListProps: { ...mdField.dropdownListProps as DropdownListMDFieldDTO, allowNewOptions: initModel.allowNewOptions } });
    }, []);

    useEffect(() => {
        setMDField({ ...mdField, dropdownListProps: { ...mdField.dropdownListProps as DropdownListMDFieldDTO, allowNewOptions: form.model.allowNewOptions } });
    }, [form.model]);

    return (
        <>
            <MDFieldAdvancedFormatsCollapse isOpen={isCollapseOpen} toggleCollapse={toggleCollapse} />

            <Row className='mt-3'>
                <Col>
                    <Collapse isOpen={isCollapseOpen}>
                        <FormGroup check>
                            <Input
                                type='checkbox'
                                id={DropdownListFormInputIds.AllowNewOptions}
                                name={DropdownListFormInputIds.AllowNewOptions}
                                dataId={DropdownListFormInputIds.AllowNewOptions}
                                onChange={form.onCheckChanged}
                                checked={form.model.allowNewOptions} />
                            <Label check for={DropdownListFormInputIds.AllowNewOptions} tooltipMessage={t(MDFieldDropdownListModalTKeys.AllowNewOptionsTooltip)}>
                                {t(MDFieldDropdownListModalTKeys.AllowNewOptions)}
                            </Label>
                        </FormGroup>
                    </Collapse>
                </Col>
            </Row>
        </>
    );
};