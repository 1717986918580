export interface QueryMDFieldsDTO {
    accountId: string;
    mdFieldIds?: string[];
}

export interface ValidateFieldNameDTO {
    accountId: string;
    name: string;
}

export interface MDFieldDTO {
    id: string;
    accountId?: string;
    name: string;
    type: MDFieldTypes;
    appliedInstanceCount?: number;
    createdByUserId?: string;
    createdByDisplayName?: string;
    system?: boolean;

    textProps?: TextMDFieldDTO;
    numberProps?: NumberMDFieldDTO;
    addressProps?: AddressMDFieldDTO;
    dateProps?: DateMDFieldDTO;
    checkboxProps?: CheckboxMDFieldDTO;
    dropdownListProps?: DropdownListMDFieldDTO;
}

export enum MDFieldTypes {
    Text = 0,
    Email,
    Address,
    Date,
    PhoneNumber,
    Checkbox, //True/False
    Number,
    DropdownList,
}

export interface TextMDFieldDTO {
    textFormat: TextFormatTypes;
    allowedCharacters: AllowedCharacterTypes;
    minChars?: number;
    maxChars?: number;
    prefix?: string;
    sensitiveDataFormat?: SensitiveDataFormatDTO;
}

export enum TextFormatTypes {
    SingleLine = 0,
    MultiLine,
}

export enum AllowedCharacterTypes {
    AllCharacters = 0,
}

export interface SensitiveDataFormatDTO {
    mode: SensitiveDataMode;
    beginningCharCount?: number;
    endingCharCount?: number;
}

export enum SensitiveDataMode {
    Hide = 0,
    Show = 1,
}

export interface NumberMDFieldDTO {
    numberFormat: NumberFormatTypes;
    minValue?: number;
    maxValue?: number;
    sensitiveDataFormat?: SensitiveDataFormatDTO;
}

export enum NumberFormatTypes {
    Number = 0,
    Percentage,
    Decimal,
    Currency,
}

export interface AddressMDFieldDTO {
    includeAddressOne: boolean;
    includeAddressTwo: boolean;
    includeCity: boolean;
    includeState: boolean;
    includePostal: boolean;
    includeCountry: boolean;
}

export interface DateMDFieldDTO {
    dateFormat: DateFormatTypes;
}

export enum DateFormatTypes {
    mmddyyyy = 0,
    ddmmyyyy,
    yyyymmdd,
    monthddyyyy,
    ddmonthyyyy,
}

export interface CheckboxMDFieldDTO {
    defaultValue: boolean;
}

export interface DropdownListMDFieldDTO {
    dropdownListFormat: DropdownListFormatTypes;
    allowNewOptions: boolean;
    options?: MDOptionItemDTO[];
    defaultOption?: string;
}

export interface MDOptionItemDTO {
    id: string;
    optionName: string;
}

export enum DropdownListFormatTypes {
    SingleSelect = 0,
    MultiSelect
}