import { AxiosResponse } from 'axios';
import { Api } from '../axios';
import { MDFieldDTO, QueryMDFieldsDTO, ValidateFieldNameDTO } from './mdFieldApiTypes';

export const useMDFieldApi = () => {

    const getMDField = async (accountId: number, mdFieldId: string): Promise<MDFieldDTO> => {
        const resp: AxiosResponse = await Api.get(`api/metadataField/${accountId}/${mdFieldId}`);
        return resp.data as MDFieldDTO;
    };

    const getAccountMDFields = async (accountId: number, start: number, count: number): Promise<MDFieldDTO[]> => {
        const resp: AxiosResponse = await Api.get(`api/metadataField/account/${accountId}/${start}/${count}`);
        return resp.data as MDFieldDTO[];
    };

    const getMDFields = async (mdFieldRequest: QueryMDFieldsDTO): Promise<MDFieldDTO[]> => {
        const resp: AxiosResponse = await Api.post('api/metadataField/query', mdFieldRequest);
        return resp.data as MDFieldDTO[];
    };

    const getMDFieldByName = async (mdFieldRequest: ValidateFieldNameDTO): Promise<MDFieldDTO> => {
        const resp: AxiosResponse = await Api.post(`api/metadataField/${mdFieldRequest.accountId}/name`, mdFieldRequest);
        return resp.data as MDFieldDTO;
    };

    const createMDField = async (mdField: MDFieldDTO): Promise<MDFieldDTO> => {
        const resp: AxiosResponse = await Api.post('api/metadataField', mdField);
        return resp.data as MDFieldDTO;
    };

    const updateMDField = async (mdField: MDFieldDTO): Promise<MDFieldDTO> => {
        const resp: AxiosResponse = await Api.put('api/metadataField', mdField);
        return resp.data as MDFieldDTO;
    };

    const deleteMDField = async (accountId: number, mdFieldId: string): Promise<MDFieldDTO> => {
        const resp: AxiosResponse = await Api.delete(`api/metadataField/${accountId}/${mdFieldId}`);
        return resp.data as MDFieldDTO;
    };

    return {
        getMDField,
        getAccountMDFields,
        getMDFields,
        getMDFieldByName,
        createMDField,
        updateMDField,
        deleteMDField
    };
};