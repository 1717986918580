import React, { useState } from 'react';
import { Alert, Modal, Text, Button } from '@efilecabinet/efc-atlantis-components';
import { PermissionsTKeys, TranslationFiles, useSafeTranslation } from '../../../../../hooks/useSafeTranslation';
import { useNodePermissionsUtilities } from '../../../../../hooks/nodePermissions/useNodePermissionsUtilities';
import { ThemeEnum } from '../../../../../hooks/useColors';
import { NodePermissionDTO } from '../../../../../api/nodePermissions/nodePermissionApiTypes';
import { useIcons } from '../../../../../hooks/useIcons';
import { useUtilities } from '../../../../../hooks/useUtilities';

interface DeletePermissionsConfirmModalProps {
    onCancel: () => void;
    closeModal: () => void;
    successCallback: () => void;
    permissionsToRemove: NodePermissionDTO[];
    permissionsToCreate: NodePermissionDTO[];
    permissionsToUpdate: NodePermissionDTO[];
}

export const DeletePermissionsConfirmModal = ({ onCancel, closeModal, successCallback, permissionsToRemove, permissionsToCreate, permissionsToUpdate }: DeletePermissionsConfirmModalProps) => {
    const { t } = useSafeTranslation(TranslationFiles.Permissions);
    const { getRoleIcon, removeNodePermissionsAsync, updateAndCreatePermissions } = useNodePermissionsUtilities();
    const { spinnerIconProps } = useIcons();
    const { formatDateTime } = useUtilities();

    const [errorStr, setErrorStr] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleCancel = () => {
        onCancel();
        closeModal();
    };

    const handleDelete = async () => {
        setIsLoading(true);
        setErrorStr('');

        try {
            await removeNodePermissionsAsync(permissionsToRemove);
            await updateAndCreatePermissions(permissionsToUpdate, permissionsToCreate);
            closeModal();
            successCallback();
        } catch (error: any) {
            console.log(error);
            setErrorStr(t(PermissionsTKeys.EditError, { error: error?.message }));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal isOpen={true} background='static' title={t(PermissionsTKeys.DeleteConfirmModalHeader)} dataId='delete-permissions-confirm-modal'>
            <Modal.Body className='mt-2'>
                {errorStr && <Alert color='danger'>{errorStr}</Alert>}
                <Text>{t(PermissionsTKeys.DeleteConfirmModalDescription)}</Text>
                <div className='mt-4'>
                    {permissionsToRemove.map((p, i) => (
                        <div className={`d-flex ${i == permissionsToRemove.length - 1 ? 'mb-2 pb-1' : 'mb-3'}`} key={p.id}>
                            {getRoleIcon(p.roleData)}
                            {p.roleData.anonymous ? <Text className='ms-1'>{t(PermissionsTKeys.AccessLinkLabel, { date: formatDateTime(p.createdOn) })}</Text> : <Text className='ms-1'>{p.role}</Text>}
                        </div>
                    ))}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleCancel} emphasis='med' dataId='delete-permissions-cancel'>{t(PermissionsTKeys.CancelButton)}</Button>
                <Button onClick={handleDelete} emphasis='high' color={ThemeEnum.Danger} dataId='delete-permissions-confirm' icon={isLoading ? spinnerIconProps : undefined}>
                    {t(PermissionsTKeys.ConfirmButton)}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
