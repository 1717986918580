import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useFormik } from 'formik';
import { Label, Collapse, NestedCheckbox, FormGroup, Input, FormFeedback } from '@efilecabinet/efc-atlantis-components';
import { NumberMDFieldModalTKeys, TranslationFiles, useSafeTranslation } from '../../../../../../../hooks/useSafeTranslation';
import { MDFieldAdvancedFormatsCollapse } from '../../__shared/MdFieldAdvancedFormatsCollapse/MdFieldAdvancedFormatsCollapse';
import { MDFieldDTO, NumberMDFieldDTO, SensitiveDataMode } from '../../../../../../../api/mdFields/mdFieldApiTypes';

export interface NumberMDFieldAdvancedFormatsProps {
    mdField: MDFieldDTO;
    setMDField: (mdField: MDFieldDTO) => void;
    setIsFormValid: (isFormValid: boolean) => void;
}

enum NumberFormInputIds {
    MinValue = 'minValue',
    MaxValue = 'maxValue',
    SensitiveDataMode = 'sensitiveDataMode',
    FirstDigits = 'firstDigits',
    LastDigits = 'lastDigits',
}

export const NumberMDFieldAdvancedFormats = (props: NumberMDFieldAdvancedFormatsProps) => {
    const { mdField, setMDField, setIsFormValid } = props;

    const { t } = useSafeTranslation(TranslationFiles.NumberMDFieldModal);

    const [isCollapseOpen, setIsCollapseOpen] = useState(false);
    const [isMinMaxValueChecked, setIsMinMaxValueChecked] = useState(false);
    const [isSensitiveDataChecked, setIsSensitiveDataChecked] = useState(false);
    const [minMaxValueError, setMinMaxValueError] = useState('');
    const [sensitiveDataError, setSensitiveDataError] = useState('');

    const formik = useFormik({
        initialValues: {
            minValue: mdField?.numberProps?.minValue ?? undefined,
            maxValue: mdField?.numberProps?.maxValue ?? undefined,
            sensitiveDataFormat: {
                mode: mdField?.numberProps?.sensitiveDataFormat?.mode ?? SensitiveDataMode.Hide,
                beginningCharCount: mdField?.numberProps?.sensitiveDataFormat?.beginningCharCount ?? undefined,
                endingCharCount: mdField?.numberProps?.sensitiveDataFormat?.endingCharCount ?? undefined,
            }
        },
        onSubmit: values => { return; }, //Formik Expects an OnSubmit
    });

    const initialEditSetup = () => {
        if (!!mdField?.textProps?.minChars || !!mdField?.textProps?.maxChars) {
            toggleMinMaxValueCheck();
        }

        if (mdField?.textProps?.sensitiveDataFormat?.mode == SensitiveDataMode.Show) {
            toggleSensitiveDataCheck();
        }
    };

    const toggleCollapse = () => {
        setIsCollapseOpen(!isCollapseOpen);
    };

    const toggleMinMaxValueCheck = () => {
        setIsMinMaxValueChecked(!isMinMaxValueChecked);
    };

    const toggleSensitiveDataCheck = () => {
        setIsSensitiveDataChecked(!isSensitiveDataChecked);
    };

    const getHideOrShowCopy = () => {
        return formik.values.sensitiveDataFormat.mode == SensitiveDataMode.Show
            ? `${t(NumberMDFieldModalTKeys.Hide)}`
            : `${t(NumberMDFieldModalTKeys.Show)}`;
    };

    const isValueNegative = (value: number | undefined) => {
        return !!value && value < 0;
    };

    const validateMinMaxValue = () => {
        let error = '';
        if (isMinMaxValueChecked && !formik.values.minValue && !formik.values.maxValue) {
            error = t(NumberMDFieldModalTKeys.MinMaxValueNeededError);
        } else if (isValueNegative(formik.values.minValue) || isValueNegative(formik.values.maxValue)) {
            error = t(NumberMDFieldModalTKeys.NegativeValueError);
        } else if (!!formik.values.minValue && !!formik.values.maxValue && formik.values.maxValue <= formik.values.minValue) {
            error = t(NumberMDFieldModalTKeys.MaxValueError);
        }
        setMinMaxValueError(error);
    };

    const validateSensitiveData = () => {
        let error = '';
        if (isSensitiveDataChecked && !formik.values.sensitiveDataFormat.beginningCharCount && !formik.values.sensitiveDataFormat.endingCharCount) {
            error = t(NumberMDFieldModalTKeys.SensitiveDataNeededError);
        } else if (isValueNegative(formik.values.sensitiveDataFormat.beginningCharCount) || isValueNegative(formik.values.sensitiveDataFormat.endingCharCount)) {
            error = t(NumberMDFieldModalTKeys.NegativeValueError);
        }
        setSensitiveDataError(error);
    };

    function handleSensitiveDataInput(e: any) {
        formik.handleChange({target: {name: 'sensitiveDataFormat.'+ e.target.name, value: e.target.value}});
    }

    useEffect(() => {
        initialEditSetup();

        setMDField({ ...mdField, numberProps: { ...mdField.numberProps as NumberMDFieldDTO, ...formik.initialValues } });
    }, []);

    useEffect(() => {
        setMDField({ ...mdField, numberProps: { ...mdField.numberProps as NumberMDFieldDTO, ...formik.values } });
    }, [formik.values]);

    useEffect(() => {
        setIsFormValid(!minMaxValueError && !sensitiveDataError);
    }, [minMaxValueError, sensitiveDataError]);

    return (
        <>
            <MDFieldAdvancedFormatsCollapse isOpen={isCollapseOpen} toggleCollapse={toggleCollapse} />

            <Row>
                <Col>
                    <Collapse isOpen={isCollapseOpen}>

                        <NestedCheckbox
                            labelName={t(NumberMDFieldModalTKeys.MinMaxValue)}
                            className='my-3'
                            isChecked={isMinMaxValueChecked}
                            onToggleCheck={toggleMinMaxValueCheck} >

                            <FormGroup>
                                <Row>
                                    <Col xs='2' className='mt-3'>
                                        <Label for={NumberFormInputIds.MinValue}>
                                            {t(NumberMDFieldModalTKeys.MinimumValue)}
                                        </Label>
                                        <div className='mx-2' style={{ width: '100px' }}>
                                            <Input
                                                id={NumberFormInputIds.MinValue}
                                                name={NumberFormInputIds.MinValue}
                                                type='number'
                                                min={0}
                                                invalid={!!minMaxValueError}
                                                value={formik.values.minValue}
                                                onChange={formik.handleChange}
                                                onBlur={validateMinMaxValue} />
                                        </div>
                                    </Col>
                                    <Col xs='2' className='mt-3'>
                                        <Label for={NumberFormInputIds.MaxValue}>
                                            {t(NumberMDFieldModalTKeys.MaximumValue)}
                                        </Label>
                                        <div className='mx-2' style={{ width: '100px' }}>
                                            <Input
                                                id={NumberFormInputIds.MaxValue}
                                                name={NumberFormInputIds.MaxValue}
                                                type='number'
                                                min={0}
                                                invalid={!!minMaxValueError}
                                                value={formik.values.maxValue}
                                                onChange={formik.handleChange}
                                                onBlur={validateMinMaxValue} />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormFeedback className={!!minMaxValueError ? 'd-block' : ''} valid={!minMaxValueError}>
                                            {minMaxValueError}
                                        </FormFeedback>
                                    </Col>
                                </Row>
                            </FormGroup>

                        </NestedCheckbox>

                        <NestedCheckbox
                            labelName={t(NumberMDFieldModalTKeys.SensitiveData)}
                            className='my-4'
                            isChecked={isSensitiveDataChecked}
                            onToggleCheck={toggleSensitiveDataCheck} >

                            <FormGroup tag='fieldset'>
                                <Row>
                                    <Col xs={2} className='mt-3'>
                                        <FormGroup check>
                                            <Input
                                                id={NumberMDFieldModalTKeys.Hide}
                                                name={NumberFormInputIds.SensitiveDataMode}
                                                type='radio'
                                                defaultChecked
                                                value={NumberMDFieldModalTKeys.Hide}
                                                onChange={formik.handleChange} />
                                            <Label for={NumberMDFieldModalTKeys.Hide}>
                                                {`${t(NumberMDFieldModalTKeys.Hide)}${t(NumberMDFieldModalTKeys.DigitsUpper)}`}
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col xs={3} className='mt-3'>
                                        <FormGroup check>
                                            <Input
                                                id={NumberMDFieldModalTKeys.Show}
                                                name={NumberFormInputIds.SensitiveDataMode}
                                                type='radio'
                                                value={NumberMDFieldModalTKeys.Show}
                                                onChange={formik.handleChange} />
                                            <Label for={NumberMDFieldModalTKeys.Show}>
                                                {`${t(NumberMDFieldModalTKeys.Show)}${t(NumberMDFieldModalTKeys.DigitsUpper)}`}
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </FormGroup>

                            <FormGroup>
                                <Row>
                                    <Col className='d-flex align-items-center'>
                                        <Label for={NumberFormInputIds.FirstDigits} className='mb-0 text-nowrap'>
                                            {getHideOrShowCopy() + `${t(NumberMDFieldModalTKeys.First)}`}
                                        </Label>
                                        <div className='mx-2' style={{ width: '100px' }}>
                                            <Input
                                                id={NumberFormInputIds.FirstDigits}
                                                name={NumberFormInputIds.FirstDigits}
                                                type='number'
                                                min={0}
                                                invalid={!!sensitiveDataError}
                                                value={formik.values.sensitiveDataFormat.beginningCharCount}
                                                onChange={handleSensitiveDataInput}
                                                onBlur={validateSensitiveData} />
                                        </div>
                                        <Label for={NumberFormInputIds.FirstDigits} className='mb-0 me-5 text-nowrap'>
                                            {t(NumberMDFieldModalTKeys.DigitsLower)}
                                        </Label>
                                        <Label for={NumberFormInputIds.LastDigits} className='mb-0 text-nowrap'>
                                            {getHideOrShowCopy() + `${t(NumberMDFieldModalTKeys.Last)}`}
                                        </Label>
                                        <div className='mx-2' style={{ width: '100px' }}>
                                            <Input
                                                id={NumberFormInputIds.LastDigits}
                                                name={NumberFormInputIds.LastDigits}
                                                type='number'
                                                min={0}
                                                invalid={!!sensitiveDataError}
                                                value={formik.values.sensitiveDataFormat.endingCharCount}
                                                onChange={handleSensitiveDataInput}
                                                onBlur={validateSensitiveData} />
                                        </div>
                                        <Label for={NumberFormInputIds.LastDigits} className='mb-0 text-nowrap'>
                                            {t(NumberMDFieldModalTKeys.DigitsLower)}
                                        </Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormFeedback className={!!sensitiveDataError ? 'd-block' : ''} valid={!sensitiveDataError}>
                                            {sensitiveDataError}
                                        </FormFeedback>
                                    </Col>
                                </Row>
                            </FormGroup>

                        </NestedCheckbox>

                    </Collapse>
                </Col>
            </Row>
        </>
    );
};